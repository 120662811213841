import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>Naga H</h1>
        <p>Fixing Things</p>
        <h2>About Me</h2>
            <p>I'm a software and a techie, I would like to play and break things related to software, hardware and Gadgets.</p>
      </header>
    </div>
  );
}

export default App;
